.form{
    //background-color: #df1111;
    width: 95%;
    margin: auto;

    form{
        padding: 45px;

        h3{
            margin: 16px auto;
            text-align: center;
            color: white;
            font-family: Arial, Helvetica, sans-serif;
        }
        input{
            width: 100%;
            margin: 25px auto;
            background: none;
            border-color: white;
            border-radius: 15px;
        }

        label{
            color: white;
            margin-top: 4px;
            span{
                color: #ff0000;
            }
        }

        .disclaimer{
            margin: auto;
            text-align: center;
            background-color: rgba($color: #df1111, $alpha: 0.1);
            //width: 99%;
            //padding: 0px;
        }

    }
    
    
}