.allStatsBox{
    box-shadow: 1px 1px 5px #60606030;
    //background-color: red;
    border-radius: 20px;

    .statsPageBox{
        background-color: #FFFFFF;
        height: 8em;
        width: 100%;
        position: relative;
        
        border-radius: 5px;

        .title{
            font-size: large;
            font-weight: 900;
            color: $dark;
            position: absolute;
            top:8%;
            left: 8%;
        }
        .stat{
            font-size: x-large;
            font-weight: 400;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);//To center
        }
        .clear{
            font-size: large;
            font-weight: bolder;
            color: $dark;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);//To center
        }
        .time{
            font-size: small;
            text-align: right;
            font-weight: 400;
            position: absolute;
            right: 4%;
            bottom: 6%;
        }
    }

    .breakdown{
        line-height: 50px;
        p{
            text-indent: 12px;
            color: $dark;
            font-weight: 900;
        }
    }

    .name{
        background-color: #FFFFFF;
        border-radius: 5px;
        ul{
            padding-top: 10px;
            list-style: none;
            
            li{
                padding: 5px 0 5px 0;
                text-indent: 12px;

                i{
                    color: $dark;
                    //background-color: black;
                    font-size: smaller;
                    
                    margin-right: 9px;
                }
            }
        }
        
    }
}