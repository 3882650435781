@import "./0_mediaquery";
@import "./1_variables";

.SingleInstancePage {
  overflow-y: auto;

  .model {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 9999;
    display: flex;

    .container {
      max-width: 30vw;
      width: 100%;
      height: fit-content;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      display: block;
      padding: 1em 2em 1em 2em;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        margin: 1em 0 1em 0;
        color: #74465c;
        text-align: center;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        color: #74465c;
        margin: 12px 0 12px 0;
        cursor: pointer;
      }
      h4:hover {
        box-shadow: 1px 1px 5px #60606070;
      }

      i {
        margin-right: 1em;
      }
    }

    .alert {
      max-width: 30vw;
      width: fit;
      height: fit-content;
      margin: auto auto 50vh auto;
      background-color: white;
      border-radius: 5px;
      display: block;
      padding: 1em 2em 1em 2em;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        color: #74465c;
        margin: 12px 0 12px 0;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        background-color: $primary;
        color: white;
        margin: 1em 0 12px 0;
        cursor: pointer;
        text-align: center;
      }

      h4:hover {
        background-color: rgba($color: $primary, $alpha: 0.7);
      }
    }
  }

  .sidepanel {
    .bar {
      display: grid;
      grid-template-columns: repeat(5, auto);
      gap: 4em;
      width: fit-content;
      position: absolute;
      background-color: rgba($color: white, $alpha: 0.9);
      left: 0;
      top: 0;
      // height: 70vh;
      z-index: 9999;

      h4 {
        color: $primary;
        font-weight: 400;
        // height: fit-content;
        text-align: center;
        // line-height: 24px;
        padding: 5px 5px 5px 5px;
        border-radius: 8px;
        // writing-mode: vertical-rl;
        cursor: pointer;
      }

      h4:hover {
        color: $dark;
      }
    }

    .Popup {
      position: absolute;
      left: 0;
      top: 27px;
      height: 70vh;
      z-index: 99999;
      width: 36vw;
      background-color: rgba($color: white, $alpha: 0.8);
      border-radius: 0 8px 8px 0;
      padding: 10px;
      overflow: hidden;

      .layers {
        min-height: 20vh;
        padding: 1em;
      }

      p {
        text-align: center;
        font-size: 10pt;
        padding: 0 0 5px 0;
        color: $primary;
      }

      h5 {
        color: $dark;
        padding: 10px 0 5px 0;
      }

      i {
        color: $dark;
        display: block;
        width: fit-content;
        margin-left: auto;
        cursor: pointer;
      }
      i:hover {
        color: $accent;
      }

      .list {
        overflow-y: auto;
        max-height: 35vh;
      }

      .lyr {
        display: grid;
        grid-template-columns: auto auto 1fr;
        gap: 8px;
        color: $dark;
        margin: 8px 0 8px 0;

        input {
          height: 16px;
          width: 16px;
          margin: 4px 0 4px 0;
        }

        .div {
          height: 24px;
          width: 24px;
          background-color: gray;
          cursor: pointer;
          border-radius: 3px;
          border: none;
          outline: none;
        }

        p {
          line-height: 24px;
          color: $dark;
          text-align: left !important;
          text-transform: capitalize;
        }
      }

      .classes {
        display: grid;
        grid-template-columns: auto auto 1fr;
        gap: 8px;
        color: $dark;
        margin: 4px 0 4px 1.5em;

        .cb {
          height: 16px;
          width: 16px;
          margin: 4px 0 4px 0;
        }

        .cr {
          height: 24px;
          width: 24px;
          cursor: pointer;
          border-radius: 3px;
          border: none;
          outline: none;
        }

        p {
          line-height: 24px;
          color: $dark;
          text-align: left !important;
          text-transform: capitalize;
        }
      }
      .range {
        display: grid;
        grid-template-columns: auto auto 1fr 1fr 1fr;
        gap: 8px;
        color: $dark;
        margin: 4px 0 4px 1.5em;

        i {
          height: 16px;
          width: 16px;
          margin: 4px 0 4px 0;
        }

        .cr {
          height: 24px !important;
          width: 24px !important;
          cursor: pointer;
          border-radius: 3px;
          border: none;
          outline: none;
        }

        p {
          line-height: 24px;
          color: $dark;
          text-align: left !important;
          text-transform: capitalize;
        }

        input {
          width: 100% !important;
        }
      }
    }
    @include maxwidth(mobile) {
      .bar {
        // height: 44vh !important;
      }
      .Popup {
        height: 50vh !important;
        width: 90vw !important;
        overflow: auto;
      }
    }
  }

  .BottomPanel {
    position: absolute;
    left: 30%;
    right: 30%;
    bottom: 0;
    height: fit-content;
    z-index: 9999;

    .opts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background-color: rgba($color: $primary, $alpha: 0.4);
      border-radius: 5px 5px 0 0;

      h4 {
        color: white !important;
        font-weight: 200 !important;
        height: fit-content;
        text-align: center;
        padding: 8px 0 8px 0;
        cursor: pointer;
      }

      h4:hover {
        color: white !important;
        background-color: $green;
        border-radius: 5px;
      }
    }

    .Popup {
      height: fit-content;
      z-index: 9999;
      background-color: rgba($color: white, $alpha: 0.9);
      min-height: 40vh;
      max-height: 65vh;
      border-radius: 8px;
      padding: 10px 1em 10px 1em;

      p {
        text-align: center;
        font-size: 10pt;
        padding: 0 0 5px 0;
      }

      i {
        color: $dark;
        display: block;
        width: fit-content;
        margin-left: auto;
        cursor: pointer;
      }
      i:hover {
        color: $accent;
      }

      // .items {
      //   width: 100%;

      //   .input {
      //     max-width: 200px;
      //     width: 100%;
      //     margin: 5px auto 5px auto;

      //     label {
      //       display: block;
      //     }

      //     input[type="number"]::-webkit-inner-spin-button {
      //       -webkit-appearance: none;
      //     }

      //     input {
      //       width: 100%;
      //       outline: none;
      //       border-top: none;
      //       border-left: none;
      //       border-right: none;
      //       border-bottom: 1px solid #60606040;
      //       padding: 8px;
      //       background-color: transparent;
      //       color: $dark;
      //     }
      //   }

      //   .aoi {
      //     display: grid;
      //     grid-template-columns: auto 1fr;
      //     gap: 10px;

      //     p {
      //       line-height: 44px;
      //       text-align: left !important;
      //     }
      //   }
      //   h3 {
      //     text-align: center;
      //     padding: 8px;
      //     color: $primary;
      //     width: 100%;
      //   }

      //   h4 {
      //     font-size: 10pt;
      //     text-align: center;
      //     padding: 10px 0 10px 0;
      //   }

      //   img {
      //     height: 44px;
      //     width: 44px;
      //     object-fit: contain;
      //   }

      //   .imgSelected {
      //     border: 2px solid $primary;
      //   }

      //   .list {
      //     padding: 1em;
      //   }

      //   .item {
      //     display: grid;
      //     grid-template-columns: auto 1fr;
      //     gap: 10px;
      //     color: $dark;
      //     margin: 16px 0 16px 0;
      //   }
      // }
    }
    @include maxwidth(tablet) {
      left: 17% !important;
    }
    @include maxwidth(mobile) {
      left: 17% !important;
      right: 15% !important;
    }
  }

  .map {
    position: relative;
    box-shadow: 2px 2px 5px #60606040;

    .select {
      max-width: 200px;
      width: 100%;
      margin: 5px auto 5px auto;

      label {
        display: block;
      }

      select {
        width: 100%;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #60606040;
        padding: 8px;
        background-color: transparent;
        color: $dark;
      }
    }

    .items {
      width: 100%;

      .link {
        max-width: 85%;
        width: 100%;
        margin: 1em auto 1em auto;

        h4 {
          text-align: center;
          padding: 8px;
          color: $primary;
          width: 100%;
        }

        input {
          width: 100%;
          padding: 1em;
          background-color: transparent;
          border-radius: 5px;
          outline: none;
          box-shadow: 1px 1px 5px #60606030;
          border: 1px solid rgba($color: $dark, $alpha: 0.5);
          margin: 1em 0 0 0;
        }
      }

      .exp {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        min-width: 150px;
        width: fit-content;
        cursor: pointer;
        padding: 5px 10px 5px 1em;

        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
        }

        p {
          line-height: 24px;
          text-align: left !important;
        }
      }
      .exp:hover {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
      }
      h3 {
        text-align: center;
        padding: 8px;
        color: $primary;
        width: 100%;
      }

      h4 {
        font-size: 10pt;
        text-align: center;
        padding: 10px 0 10px 0;
      }

      img {
        height: 44px;
        width: 44px;
        object-fit: contain;
      }

      .imgSelected {
        border: 2px solid $primary;
      }

      .list {
        padding: 1em;
      }

      .item {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        color: $dark;
        margin: 16px 0 16px 0;
      }
    }

    .popup {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      display: flex;
      align-items: center;
      z-index: 999999;
      animation: zoom 1s ease;

      .container {
        background-color: white;
        border-radius: 8px;
        padding: 1em 2em 1em 2em;
        margin: 0 auto 0 auto;
        color: #000000;
        max-width: 70vw;
        max-height: 70vh;
        height: fit-content;
        overflow-y: auto;
        width: fit-content;
        position: relative;

        h3 {
          text-align: center;
          font-size: x-large !important;
        }

        .fa-times {
          position: absolute;
          right: 1em;
          top: 10px;
          padding: 10px;
          cursor: pointer;
          color: $primary;
        }
        .fa-times:hover {
          color: $accent;
        }

        hr {
          margin-bottom: 10px;
        }

        i {
          display: block;
          width: fit-content;
          padding: 5px;
          margin: 0 0 0 auto;
          color: $accent;
        }
        i:hover {
          color: $primary;
        }

        h3 {
          padding: 0px 0 10px 0;
          font-size: medium;
        }

        form {
          textarea {
            resize: none;
            width: 100%;
            margin: auto;
            padding: 4px;
            font-size: 18px;
          }
        }

        .input-map {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }
          textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          textarea:focus {
            border-bottom: 2px solid $primary;
          }
          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
        .usrselect {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }

          select {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        p {
          font-size: medium;
          margin: 0px 0 5px 0;

          b {
            color: #74465c;
            cursor: pointer;
          }
        }

        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 12px;
          width: 80%;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
      }
      @include maxwidth(mobile) {
        .container {
          max-width: 85% !important;
        }
      }
      @include maxwidth(tablet) {
        .container {
          max-width: 85% !important;
        }
      }
    }

    @include maxwidth(mobile) {
      height: 60vh !important;
      width: 100% !important;
      .leaflet-container {
        height: 60vh !important;
      }
    }
  }

  .cont {
    padding: 2em;

    .input-map {
      margin: 10px 0 10px 0;

      label {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }
      input {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .div2equal {
      gap: 2em !important;
      margin: 2em 0 2em 0;
    }

    .img {
      position: relative;
      width: 100%;
      height: calc(25vw * 0.6);

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: center;
        border-radius: 5px;
      }

      input {
        display: none;
      }

      i {
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        left: 45%;
        position: absolute;
        bottom: -20px;
        cursor: pointer;
        background-color: $accent;
        border-radius: 5px;
      }
      i:hover {
        background-color: $dark;
        color: $accent;
      }

      @include maxwidth(tablet) {
        height: 200px !important;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 1.3;
      max-width: 80%;
      color: $primary;
      text-align: center;
      display: block;
      margin: 2em auto 10px auto;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
      max-width: 60%;
      display: block;
      margin: 10px auto 10px auto;
      text-align: center;
    }
  }

  .desc {
    padding: 2em;
    margin: 1em;
    background-color: white;

    h2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #000000;
      text-align: center;
    }

    h3 {
      margin: 0 !important;
      padding: 0 !important;
      text-align: center !important;
      width: 100% !important;
    }

    .description {
      * {
        all: initial;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        color: #000000;
        width: 100% !important;
        width: fit-content;
        text-align: left;
      }
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      margin: 1em 0 1em 0;

      span {
        color: $dark;
        font-weight: 600;

        .sourcelink{
          
        }
      }
    }

    .input-map {
      margin: 10px 0 10px 0;

      label {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }
      input {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .div2equal {
      gap: 2em !important;
      margin: 2em 0 2em 0;
    }

    .img {
      position: relative;
      width: 100%;
      height: calc(25vw * 0.6);

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: center;
        border-radius: 5px;
      }

      input {
        display: none;
      }

      i {
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        left: 45%;
        position: absolute;
        bottom: -20px;
        cursor: pointer;
        background-color: $accent;
        border-radius: 5px;
      }
      i:hover {
        background-color: $dark;
        color: $accent;
      }

      @include maxwidth(tablet) {
        height: 200px !important;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 1.3;
      max-width: 80%;
      color: $primary;
      text-align: center;
      display: block;
      margin: 2em auto 10px auto;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
      max-width: 60%;
      display: block;
      margin: 10px auto 10px auto;
      text-align: center;
    }
  }
  @include maxwidth(mobile) {
    padding-bottom: 18% !important;
    margin-left: -24px;
  }
  @include maxwidth(tablet) {
    padding-bottom: 18% !important;
    margin-left: -24px;
  }
}
