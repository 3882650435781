@import "./0_mediaquery";
@import "./1_variables";

.faqs {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;

  .title {
    text-align: center;
    color: #74465c;
    padding: 15px 0 15px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .addNew {
      display: flex;
      align-items: center;
      background-color: $primary;
      color: white;
      border: none;
      padding: 9px;
      width: 7vw;
      cursor: pointer;
      margin-left: 23em;
    }
  }
  .accordion {
    display: block;
    padding: 5px 8em 5px 8em;
    .accordion-header {
      display: grid;
      align-items: center;
      background: white;
      padding: 10px;
      box-shadow: 1px;
      h4 {
        color: black;
      }
      i {
        text-align: end;
      }
    }
    .accordion-body {
      border: 1px solid #ddd;
      animation: 1000ms slide-down;

      @keyframes slide-down {
        from {
          margin-top: -1%;
        }
        to {
          margin-top: 0%;
        }
      }

      p {
        color: black;
        padding: 1em 2em;
      }
      .date {
        text-align: right;
        padding: 1em;
      }
      .accordion-footer {
        button {
          background-color: $primary;
          padding: 10px;
          color: white;
          border: $primary;
          border-radius: 10px;
        }
      }
    }
  }
}
