.PaymentsPage {
  padding: 2em;
  overflow-y: auto;

  .messagestatistics {
    color: black;
    column-gap: 30px;
    padding: 0 0 2em 0;

    .select {
      padding-bottom: 30px;
    }

    select {
      color: #74465c;
      border: none;
      display: block;
      margin: auto;

      option {
        border: none;
      }
    }

    .totals {
      //display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
      margin-top: 1em;

      .usrStats {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3em;

        .usrBox {
          box-shadow: 1px 1px 5px #60606030;
          background: #ffffff;
          border-radius: 8px;
          padding: 1em;
          height: 200px;
          display: grid;
          grid-template-rows: auto 1fr auto;
          gap: 10px;

          h4 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #74465c;
          }

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 250;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            color: #000000;
            display: block;
            margin: auto;
          }

          h6 {
            font-family: "Inter";
            font-style: italic;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            color: #000000;
          }
        }
        @include maxwidth(mobile) {
          display: flex !important;
          flex-direction: column;
        }
        @include maxwidth(tablet) {
          gap: 1em !important;
        }
      }
    }
  }

  .label {
    text-align: center;
    margin: 2em 0 2em 0;
    color: $dark;
    font-weight: 600;
  }

  .container {
    box-shadow: 1px 1px 5px #60606030;
    border-radius: 5px;
    width: fit-content;
  }
  .ppu {
    background-color: #ffffff;
    height: 2em;
    font-size: x-large;
    font-weight: 800;
    align-items: center;
    display: flex;

    h5 {
      margin: 0 2em 0 2em;
    }
  }
  .userlist {
    width: 100%;
    table {
      width: 100%;
    }
    .thead {
      background-color: none;
      border-color: $bg2;
      height: 2em;
    }
    .tbody {
      background-color: #ffffff;
      .check {
        color: white;
        background-color: #000000;
      }
    }
    td {
      height: 2em;
      text-align: center;
      padding: 2px 0 2px 0;
    }
    .sn {
      width: 30em;
    }

    @include maxwidth(tablet) {
      margin: 0 0 0 -24px !important;
      table {
      }
    }
  }
  @include maxwidth(mobile) {
    padding-bottom: 18% !important;
    margin-left: -24px;
  }
  @include maxwidth(tablet) {
    padding-bottom: 18% !important;
    margin-left: -24px;
  }
}
