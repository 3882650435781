.StatisticsPage {
  padding: 1em;
  overflow-y: auto;

  .chart {
    background-color: white;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    padding: 1em;

    h3 {
      color: $primary;
      font-size: medium;
      margin-bottom: 1em;
    }
  }

  .top {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;

    .tp_item {
      padding: 1em;
      background-color: white;
      border-radius: 8px;

      p {
        color: black;
        font-size: small;
        font-weight: 500;
      }

      h6 {
        color: gray;
        font-size: small;
        font-weight: 400;
        text-align: right;
      }

      h1 {
        color: $primary;
        font-weight: bolder;
        margin: 5px 0 5px 0;
        font-size: large;
      }
    }
  }

  .middle {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  .bottom {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1em;

    .rd {
      height: 100%;
      display: grid;
      grid-template-rows: repeat(4, 1fr);
      gap: 1em;
    }

    .row {
      box-shadow: 1px 1px 5px #60606030;
      border-radius: 5px;
      padding: 10px;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 10px;
      height: fit-content;
      background-color: white;

      i {
        display: block;
        font-size: xx-large;
        border: 1px solid #60606030;
        border-radius: 50%;
        padding: 10px;
      }

      div {
        margin: auto;
        width: 100%;

        h4 {
          font-size: medium;
          color: rgb(30, 30, 30);
          font-weight: 500;
        }

        p {
          font-size: large;
          margin-top: 10px;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .top {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }
  }
  @include maxwidth(tablet) {
    .top {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }
  }
}
