@import "./0_mediaquery";
@import "./1_variables";

.LoginPage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-image: url("../assets//imgs/saturn.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 1em;
  color: white;

  .forgotPassword {
    position: absolute;
    background-color: rgba($color: #ffffff, $alpha: 1);
    border-radius: 30px;
    max-width: 400px;
    width: 100%;
    z-index: 9999;
    top: 0;
    padding: 1em 2em 1em 2em;
    top: 40px;
    right: 0;
    margin: auto;

    h4 {
      color: black;
      padding: 5px;
    }
    p {
      color: #000000 !important;
      text-align: left;
      padding: 1px;
    }
    input {
      width: 100%;
      padding: 10px;
      background-color: transparent;
      border-radius: 5px;
      outline: none;
      box-shadow: 1px 1px 5px #60606030;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #74465c;
      font-size: 12px;
      margin-top: 15px;
    }

    button {
      background-color: #0c164f;
      width: 110px;
      height: 40px;
      color: white;
      border-radius: 5px;
      padding: 10px;
      margin-top: 35px;
    }
    .cancel {
      text-align: center;
      color: red;
      cursor: pointer;
      margin: 1em 0 1em 0;
    }
    .cancel:hover {
      color: #74465c;
    }
    .err {
      padding: 10px;
    }
  }

  .top {
    max-width: 85vw;
    width: 100%;
    padding: 1em 0 1em 0;
    margin: 0 auto 0 auto;

    .div1 {
      display: flex;
      align-items: center;
      width: fit-content;
      margin: 0 auto 0 0;
      h2 {
        margin: 1em 0 1em 0;
        font-size: x-large;
        letter-spacing: 1px;
        color: white;
      }
    }

    img {
      max-height: 54px;
      height: 100%;
      object-fit: contain;
    }

    .div2 {
      width: fit-content;
      margin: 0 0 0 auto;
      text-align: right;

      h1 {
        font-size: large;
      }
      p {
        font-size: 16px;
      }
    }
  }

  .login {
    max-width: 400px;
    width: 100%;
    height: fit-content;
    margin: auto;
    background-color: rgba($color: #000000, $alpha: 0.4);
    padding: 1em 2em 1em 2em;
    text-align: center;
    position: relative;
    border-radius: 8px;
    box-shadow: 2px 2px 15px rgba($color: white, $alpha: 0.3);

    h3 {
      margin: 1em 0 1em 0;
      font-size: 20px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      color: #ffffff;
      letter-spacing: 1px;
    }

    .err {
      height: 10px;
      line-height: 10px;
      display: block;
      text-align: center;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 200;
      font-size: 12px;
      color: #ffffff;
      margin: 1em 0 0 0;
    }

    span {
      color: $darkComplimentary;
      cursor: pointer;
    }
    span:hover {
      color: $primary;
    }

    .lg_input {
      position: relative;
      margin: 1em 0 1em 0;

      label {
        display: block;
        font-size: 12px;
        text-align: left;
        margin: 10px 0 10px 0;
      }

      input {
        width: 100%;
        outline: none;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.7);
        border-radius: 5px;
        font-size: 12px;
        line-height: 12px;
        padding: 10px 32px 10px 32px;
        height: 32px;
        background: transparent;
        color: white;
        text-align: left;
      }

      input[type="password"] {
        text-align: center;
        letter-spacing: 5px;
      }

      .fa-at,
      .fa-lock {
        position: absolute;
        bottom: 10px;
        left: 10px;
        height: 12px;
        width: 12px;
        font-size: 12px;
        line-height: 12px;
      }

      .fa-eye {
        position: absolute;
        bottom: 10px;
        right: 10px !important;
        height: 12px;
        width: 12px;
        font-size: 12px;
        line-height: 12px;
        cursor: pointer;
      }
      .fa-eye:hover {
        color: $accent;
      }
    }
    @include maxwidth(mobile) {
      max-width: 85% !important;
    }
  }

  .bottom {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 1em;
    width: fit-content;
    margin: 0 auto 1em auto;
    padding: 1em 0 1em 0;

    .function {
      text-align: center;
      cursor: pointer;
      width: fit-content;

      i {
        font-size: 11px;
      }

      p {
        display: inline-block;

        font-size: 11px;
      }
    }
    .function:hover {
      color: $accent;
    }

    @include maxwidth(mobile) {
      grid-template-columns: repeat(2, auto);

      i {
        font-size: x-small !important;
      }
      p {
        font-size: x-small !important;
      }
    }
    @include maxwidth(tablet) {
      display: flex;
      margin-top: 30px;
    }
  }
}
