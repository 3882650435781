@import "@fontsource/roboto/300.css";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/500.css";
@import "@fontsource/roboto/700.css";
@import "./Styles/0_mediaquery";
@import "./Styles/1_variables";
@import "./Styles/2_utils";
@import "./Styles/3_NotFound";
@import "./Styles/StatisticsPage";
@import "./Styles/InstancesPage";
@import "./Styles/NewInstancesPage";
@import "./Styles/StatsPageBox.scss";
@import "./Styles/PaymentsPage";
@import "./Styles/Form.scss";
@import "./Styles/faqs.scss";
@import "./Styles/timeseries.scss";

.sr-only {
  display: none;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: $bg1;
  display: table;
  height: 100%;
  width: 100%;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "roboto";
  text-transform: capitalize;
}

h4 {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

p {
  font-family: "roboto";
}

.wrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100% !important;
  overflow: hidden !important;

  .MainContent {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden !important;

    .mypopup {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      display: flex;
      align-items: center;
      z-index: 999999;

      .container {
        background-color: white;
        border-radius: 8px;
        padding: 1em;
        margin: 0 auto 0 auto;
        color: #000000;
        max-width: 400px;
        width: 100%;
        text-align: center;

        i {
          display: block;
          width: fit-content;
          padding: 5px;
          margin: 0 0 0 auto;
          color: $accent;
        }

        i:hover {
          color: $primary;
        }

        h3 {
          padding: 0px 0 10px 0;
          font-size: medium;
        }

        form {
          textarea {
            resize: none;
            width: 100%;
            margin: auto;
            padding: 4px;
            font-size: 18px;
          }
        }

        .input-map {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }

          textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }

          textarea:focus {
            border-bottom: 2px solid $primary;
          }

          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }

          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        .usrselect {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }

          select {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }

          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        p {
          font-size: medium;
          margin: 0px 0 10px 0;

          span {
            color: #74465c;
            cursor: pointer;
          }
        }

        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 12px;
          width: 80%;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }

        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
      }

      @include maxwidth(mobile) {
        .container {
          max-width: 85% !important;
        }
      }

      @include maxwidth(tablet) {
        .container {
          max-width: 85% !important;
        }
      }
    }

    .main {
      color: white;
      padding: 0 2em 0 2em;
      display: grid;
      grid-template-columns: 1fr auto;
      width: 100%;
      background-color: $primary;

      .fa-bars {
        display: none;
      }

      h4 {
        line-height: 64px;
        font-weight: 400;

        @include maxwidth(mobile) {
          margin-left: 5px;
        }
      }

      .user {
        display: grid;
        grid-template-columns: auto auto;
        gap: 15px;
        margin: 10px 0 10px 0;

        img {
          height: 44px;
          width: 44px;
          border-radius: 44px;
          object-fit: cover;
          object-position: center;
        }

        .dropdown {
          h5 {
            color: white;
            cursor: pointer;
            font-size: 10pt;
            letter-spacing: 1px;
            line-height: 44px;
          }

          span {
            margin-left: 1em;
          }
        }

        select {
          background-color: transparent;
          border: none;
          border-radius: 5px;
          color: white;
          outline: none;

          option {
            background-color: $primary;
            padding: 1em;
            cursor: pointer;
          }
        }
      }

      .userOptions {
        position: absolute;
        visibility: hidden;
        background-color: $primary;
        width: 130px;
        color: white;

        h5 {
          line-height: 24px;
          border-bottom: 1px solid gray;
          padding-left: 5px;
          cursor: pointer;
        }

        h5:hover {
          background-color: whitesmoke;
          color: #030092;
        }
      }

      .showSettings {
        visibility: visible;
      }

      @include maxwidth(mobile) {
        padding: 0 !important;
      }
    }
  }
}

.header {
  padding: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: $secondary;
  width: 100%;
  color: white;

  .dropdown {
    position: absolute;
    right: 1.5em;
    top: 40px;
    background-color: white;
    box-shadow: 2px 2px 10px #60606030;
    border-radius: 5px;
    z-index: 99999;

    i {
      color: $primary;
      margin-right: 0px;
      width: 24px;
    }

    p {
      color: #000000;
      font-size: small;
      padding: 8px 1em 8px 1em;
      cursor: pointer;
    }

    p:hover {
      background-color: #60606030;
    }
  }

  .fa {
    cursor: pointer;
  }

  .fa:hover {
    color: $accent;
  }

  .right {
    margin-left: auto;
    width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 8px;
  }

  @include maxwidth(mobile) {
    grid-template-columns: 1fr;

    .fa-bars {
      display: none;
    }
  }

  @include maxwidth(tablet) {
    grid-template-columns: 1fr;

    .fa-bars {
      display: none;
    }
  }
}

.navigation {
  height: 100%;
  overflow-y: auto;
  background-color: white;
  position: relative;

  .logo {
    color: white;
    text-align: center;
    background-color: $primary;
    padding: 1em 10px 1.5em 10px;

    h3 {
      margin: 10px 0 10px 0;
      font-size: medium;
      font-weight: 500;
    }

    img {
      max-width: 70px;
      width: 100%;
      display: block;
      margin: 0 auto 10px auto;
    }
  }

  h6 {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    font-size: small;
    height: fit-content;
    display: block;
    font-weight: 500;
    text-align: center;

    a {
      color: $accent !important;
      font-size: small !important;
      padding: 5px !important;
    }
  }

  .item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 10px;
    padding: 10px 1em 10px 1em !important;
    cursor: pointer;
    height: fit-content !important;

    * {
      font-size: medium;
      color: $primary;
      display: grid;
      margin: auto 0 auto 0;
    }

    .fa {
      font-size: large;
      display: grid;
      margin: auto;
    }
  }

  a {
    cursor: pointer;
    padding: 4px 2em 4px 2em;
    display: block;
    font-size: medium;
    text-decoration: none;
    color: rgb(30, 29, 29);
  }

  a:hover {
    color: $primary;
  }

  @include maxwidth(mobile) {
    a {
      padding: 4px 1em 4px 1em !important;
    }

    h6 {
      display: none;
    }
  }

  @include maxwidth(tablet) {
    a {
      padding: 4px 1em 4px 1em !important;
    }

    h6 {
      display: none;
    }
  }
}

.button {
  button {
    min-width: 200px;
    width: fit-content;
    margin: 16px auto 16px auto;
    border: none;
    background-color: $primary;
    color: white;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    display: block;
  }

  button:hover {
    background-color: rgba($color: $primary, $alpha: 0.6);
  }
}

.buttonother {
  width: fit-content;
  margin: 5px auto 5px auto;

  button {
    border: none;
    background-color: none;
    color: $dark;
    cursor: pointer;
  }
}

.thumbnail {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 1px 5px #60606030;
  position: relative;
  cursor: pointer;
  transition: 300ms;

  &:hover .desc {
    background-color: rgba($color: $lightPrimary, $alpha: 1);
    transition: 300ms;
  }

  img {
    height: calc(20vw * 0.6);
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .desc {
    background-color: rgba($color: $lightPrimary, $alpha: 0.5);
    padding: 8px;
    color: $dark;
    text-align: center;

    h4 {
      font-weight: 500;
      font-size: 10pt;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px;
      color: #fff;
    }

    p {
      font-size: 8pt;
      color: #fff;
    }
  }

  .options {
    background-color: rgba($color: $accent, $alpha: 0.96);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    p {
      cursor: pointer;
      text-align: center;
      color: white;
      padding: 12px;
      font-size: 11pt;
    }

    p:hover {
      background-color: $secondary;
    }
  }
}

.div2equal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;

  @include maxwidth(mobile) {
    display: block !important;
  }
}

.div3equal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.pagination {
  display: grid;
  gap: 10px;
  width: fit-content;
  margin: 10px auto 10px auto;

  p {
    display: block;
    font-size: medium;
    width: 24px !important;
    height: 24px;
    margin: auto;
    background-color: $primary;
    text-align: center;
    line-height: 24px;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  p:hover {
    background-color: $accent;
  }

  .active {
    background-color: $accent;
  }

  .fa-arrow-right,
  .fa-arrow-left {
    background-color: $secondary;
    cursor: pointer !important;
    font-size: small;
    border-radius: 6px;
    color: white;
    width: 24px;
    text-align: center;
    height: 24px !important;
    line-height: 24px !important;
  }

  .fa-arrow-right:hover,
  .fa-arrow-left:hover {
    background-color: $accent;
  }
}

.cpagination {
  display: flex;
  gap: 10px;
  width: fit-content;
  margin: 10px auto 10px auto;

  p {
    display: block;
    font-size: medium !important;
    min-width: 24px;

    width: fit-content !important;
    margin: 0 10px 0 10px;
    font-weight: 400;
    background-color: $secondary;
    text-align: center;
    height: 24px !important;
    line-height: 24px;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  h5 {
    display: block;
    font-size: medium !important;
    min-width: 24px;
    padding: 0 4px;
    width: fit-content !important;
    margin: auto;
    font-weight: 400;
    background-color: $primary;
    text-align: center;
    line-height: 24px !important;
    color: white !important;
    border-radius: 5px;
    font-size: small;
    cursor: pointer;
  }

  h5:hover {
    background-color: $accent;
  }

  .active {
    background-color: $accent;
  }

  .fa-arrow-right,
  .fa-arrow-left {
    background-color: $secondary;
    cursor: pointer !important;
    font-size: small;
    border-radius: 6px;
    color: white;
    width: 24px;
    text-align: center;
    height: 24px !important;
    line-height: 24px !important;
  }

  .fa-arrow-right:hover,
  .fa-arrow-left:hover {
    background-color: $accent;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $dark;
  border-radius: 10px;
}

.error {
  height: 30vh !important;
  display: flex !important;
  justify-items: center !important;

  p {
    width: 80% !important;
    display: block !important;
    color: gray !important;
    text-align: center !important;
    height: fit-content !important;
    margin: auto !important;
    font-size: large !important;
  }
}

.ripple {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba($color: #000000, $alpha: 0.2);
  display: flex;
  align-items: center;

  .container {
    width: fit-content;
    margin: auto;
  }

  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }

  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }

    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }

    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }

    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
}

//loading animation
.loading {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.2);

  .wrap {
    width: fit-content;
    height: fit-content;
    margin: auto;

    .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }

    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }

    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #fff;
      margin: -4px 0 0 -4px;
    }

    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }

    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }

    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }

    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }

    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }

    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }

    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }

    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }

    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }

    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }

    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }

    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }

    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }

    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }

    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }

    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }

    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

//map tooltip
.label {
  background: rgba(255, 255, 255, 0);
  border: 0;
  border-radius: 0px;
  box-shadow: 0 0px 0px;
}

//remove arrows input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  transition: 0s !important;
}

.wave_loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes wave {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  .wave {
    width: 5px;
    height: 100px;
    background: linear-gradient(45deg, $accent, $secondary);
    margin: 10px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }

  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }

  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }

  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }

  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }

  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }

  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }

  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }

  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }

  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }

  @keyframes wave {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }
}
