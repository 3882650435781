@import "./0_mediaquery";
@import "./1_variables";

.MessagesPage {
  padding: 1em;
  overflow-y: auto;

  .top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;

    .tp_item {
      padding: 1em;
      background-color: white;
      border-radius: 8px;

      p {
        color: black;
        font-size: small;
        font-weight: 500;
      }

      h6 {
        color: gray;
        font-size: small;
        font-weight: 400;
        text-align: right;
      }

      h1 {
        color: $primary;
        font-weight: bolder;
        margin: 5px 0 5px 0;
        font-size: large;
      }
    }
  }

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    position: relative;
    margin-top: 1em;

    h3 {
      padding: 10px 0 10px 0;
      font-size: medium;
    }

    .content {
      min-height: 30vh;

      .accordion-body {
        margin: 5px 0 5px 0;
        border-radius: 8px;
        box-shadow: 1px 1px 5px #60606030;
        border: 1px solid #60606030;
        padding: 1em;
        cursor: pointer;

        h4 {
          font-weight: 500;
          margin: 4px 0 4px 0;
          font-size: medium;
          color: $primary;
        }

        p {
          font-size: small;
          margin: 4px 0 4px 0;
          font-weight: 400;
        }

        b {
          font-weight: 500;
          color: $secondary;
        }

        .accordion-footer {
          width: fit-content;
          margin: 10px 0 0 auto;
          display: grid;
          grid-template-columns: auto auto auto auto;
          gap: 1em;

          a {
            text-decoration: none;
            font-size: small;
            color: $primary;
            cursor: pointer;
          }

          a:hover {
            color: $secondary;
          }
        }
      }
    }

    .new {
      button {
        display: block;
        margin: 1em auto 1em auto;
        padding: 12px;
        width: 80%;
        background-color: $primary;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid #60606070;
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }
      .usrinput {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        textarea {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        textarea:focus {
          border-bottom: 2px solid $primary;
        }

        input {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
      .usrselect {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin: 0px 0 10px 0;
          font-weight: 400;
        }

        select {
          margin: 0px 0 10px 0;
          padding: 10px;
          width: 100%;
          background-color: #60606030;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 2px solid #60606070;
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .top {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }
  }
  @include maxwidth(tablet) {
    .top {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }
  }
}
