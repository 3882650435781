@import "./0_mediaquery";
@import "./1_variables";

.CommentsPage {
  padding: 2em;
  overflow-y: auto;

  .messagestatistics {
    color: black;
    column-gap: 30px;
    padding: 0 0 2em 0;

    .select {
      margin: 10px 0 10px 0;

      h4 {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }

      select {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .totals {
      //display: grid;
      //grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;
      margin-top: 1em;

      .usrStats {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3em;

        .usrBox {
          box-shadow: 1px 1px 5px #60606030;
          background: #ffffff;
          border-radius: 8px;
          padding: 1em;
          height: 200px;
          display: grid;
          grid-template-rows: auto 1fr auto;
          gap: 10px;

          h4 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #74465c;
          }

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 250;
            font-size: 36px;
            line-height: 44px;
            text-align: center;
            color: #000000;
            display: block;
            margin: auto;
          }

          h6 {
            font-family: "Inter";
            font-style: italic;
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            text-align: right;
            color: #000000;
          }
        }
        @include maxwidth(mobile) {
          display: flex !important;
          flex-direction: column;
        }
        @include maxwidth(tablet) {
          gap: 1em !important;
        }
      }
    }
  }
  .messages {
    color: #74465c;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 10px;
    width: 100%;
    margin: auto;

    .messagehead {
      padding-bottom: 30px;
      margin-left: 1em;

      p.display {
        float: right;
        margin-right: 2em;
      }
    }

    .messagecontent {
      width: 98%;
      margin: auto;
      display: grid;
      grid-template-rows: auto;
      row-gap: 25px;
    }
  }

  @include maxwidth(mobile) {
    padding-bottom: 18% !important;
    margin-left: -24px;
  }
  @include maxwidth(tablet) {
    padding-bottom: 18% !important;
  }
}
