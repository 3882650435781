@import "./0_mediaquery";
@import "./1_variables";

.NewInstancesPage {
  overflow-y: auto;
  height: 100%;

  .model {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 9999;
    display: flex;

    .container {
      max-width: 30vw;
      width: 100%;
      height: fit-content;
      margin: auto;
      background-color: white;
      border-radius: 5px;
      display: block;
      padding: 1em 2em 1em 2em;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        margin: 1em 0 1em 0;
        color: #74465c;
        text-align: center;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        color: #74465c;
        margin: 12px 0 12px 0;
        cursor: pointer;
      }
      h4:hover {
        box-shadow: 1px 1px 5px #60606070;
      }

      i {
        margin-right: 1em;
      }
    }

    .alert {
      max-width: 30vw;
      width: fit;
      height: fit-content;
      margin: auto auto 50vh auto;
      background-color: white;
      border-radius: 5px;
      display: block;
      padding: 1em 2em 1em 2em;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        color: #74465c;
        margin: 12px 0 12px 0;
      }

      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 1em 10px 1em;
        width: 100%;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
        background-color: $primary;
        color: white;
        margin: 1em 0 12px 0;
        cursor: pointer;
        text-align: center;
      }

      h4:hover {
        background-color: rgba($color: $primary, $alpha: 0.7);
      }
    }
  }

  .top_panel {
    position: absolute;
    left: 10px;
    top: 10px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid $accent;
    width: fit-content !important;

    .fa-bars {
      display: none;
      color: #74465c;
      padding: 8px;
      cursor: pointer;
      margin: auto;
    }
    .fa-bars:hover {
      color: #917681;
    }

    .tp_cont {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 5px;
      max-width: 350px;
    }

    .active {
      color: $accent;
    }

    p {
      padding: 4px 1em 4px 1em;
      cursor: pointer;
      text-align: center;
      font-size: small;
    }

    i {
      margin-right: 12px;
    }

    @include maxwidth(mobile) {
      .tp_cont {
        display: none;
      }
      .fa-bars {
        display: block;
      }
    }
    @include maxwidth(tablet) {
      .cont {
        display: none !important;
      }
      .fa-bars {
        display: block;
      }
    }
  }

  .BottomPanel {
    position: absolute;
    left: 30%;
    right: 30%;
    bottom: 0;
    height: fit-content;
    z-index: 9999;

    .opts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background-color: rgba($color: $primary, $alpha: 0.4);
      border-radius: 5px 5px 0 0;

      h4 {
        color: white !important;
        font-weight: 200 !important;
        height: fit-content;
        text-align: center;
        padding: 8px 0 8px 0;
        cursor: pointer;
      }

      h4:hover {
        color: white !important;
        background-color: $accent;
        border-radius: 5px;
      }
    }

    .Popup {
      height: fit-content;
      z-index: 9999;
      background-color: rgba($color: white, $alpha: 0.9);
      min-height: 40vh;
      max-height: 65vh;
      border-radius: 8px;
      padding: 10px 1em 10px 1em;

      p {
        text-align: center;
        font-size: 10pt;
        padding: 0 0 5px 0;
      }

      i {
        color: $dark;
        display: block;
        width: fit-content;
        margin-left: auto;
        cursor: pointer;
      }
      i:hover {
        color: $accent;
      }

      .items {
        width: 100%;

        .input {
          margin: 10px 0 10px 0;

          label {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }
          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        .aoi {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;

          p {
            line-height: 44px;
            text-align: left !important;
          }
        }
        h3 {
          text-align: center;
          padding: 8px;
          color: $primary;
          width: 100%;
        }

        h4 {
          font-size: 10pt;
          text-align: center;
          padding: 10px 0 10px 0;
        }

        img {
          height: 44px;
          width: 44px;
          object-fit: contain;
        }

        .imgSelected {
          border: 2px solid $primary;
        }

        .list {
          padding: 1em;
        }

        .item {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          color: $dark;
          margin: 16px 0 16px 0;
        }
      }
    }
    @include maxwidth(mobile) {
      left: 17% !important;
      width: 63vw;
    }
  }

  .map {
    position: relative;
    box-shadow: 2px 2px 5px #60606040;

    .ol-zoom {
      position: absolute;
      top: auto;
      bottom: 10px;
      right: 10px;
      left: auto;
      * {
        cursor: pointer;
        background-color: $accent;
        font-size: medium;
      }
      *:hover {
        background-color: $primary;
      }
    }

    .ol-zoom-extent {
      position: absolute;
      top: auto;
      bottom: 10px;
      right: 44px;
      left: auto;

      * {
        cursor: pointer;
        background-color: $accent;
        font-size: medium;
      }
      *:hover {
        background-color: $primary;
      }
    }

    .ol-scale-bar {
      position: absolute;
      bottom: 10px;
      left: 45%;
      right: auto;
      top: auto;

      * {
        font-size: small;
      }
    }

    .download {
      position: absolute;
      bottom: 10px;
      right: auto;
      left: 10px;
      z-index: 9999;
      border: 3px solid rgba(210, 190, 167, 0.8);
      border-radius: 3px;

      a {
        width: fit-content;
        color: white;
        background-color: $accent;
        display: block;
        padding: 3px 10px 3px 10px;
        font-size: small;
        border-radius: 2px;
        cursor: pointer;
      }
      a:hover {
        background-color: $accent;
      }
      i {
        margin-right: 5px;
        font-size: smaller;
      }
    }

    .base_selector {
      position: absolute;
      right: 78px;
      left: auto;
      bottom: 10px;
      top: auto;
      background-color: $accent;
      border: 3px solid rgba(210, 190, 167, 0.8);
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;

      .fa-map {
        padding: 3px 2px 3px;
        color: white;
      }
    }

    .base_selector:hover {
      background-color: $accent;
    }

    .layers {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: white, $alpha: 0.8);
      border: 1px solid $accent;
      z-index: 99999;
      display: flex;
      align-items: center;
      align-content: center;

      .cont {
        width: 100%;
        display: block;
      }

      h3 {
        text-align: center;
        color: $accent;
      }

      .fa-close {
        display: block;
        color: $accent;
        cursor: pointer;
        width: fit-content;
        height: fit-content;
        margin: 0 10px 0 auto !important;
        font-size: large;
      }
      .fa-close:hover {
        color: #74465c;
      }

      .basemaps {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        padding: 1em;
        width: 100%;

        p {
          padding: 4px;
          text-align: center;
          font-size: small;
        }

        .active {
          border: 2px solid $accent;
          background-color: white;
          border-radius: 8px;
          cursor: pointer;
          overflow: hidden;
          img {
            max-width: 100%;
            width: 100%;
            height: 120px;
            object-fit: cover;
          }
        }

        .item {
          background-color: white;
          border-radius: 8px;
          cursor: pointer;
          border: 2px solid transparent;
          overflow: hidden;

          img {
            max-width: 100%;
            width: 100%;
            height: 120px;
            object-fit: cover;
          }
        }

        .item:hover {
          border: 1px solid $accent;
        }
      }
    }

    .select {
      margin: 10px 0 10px 0;

      h4 {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }

      select {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      select:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .items {
      width: 100%;

      .link {
        max-width: 85%;
        width: 100%;
        margin: 1em auto 1em auto;

        h4 {
          text-align: center;
          padding: 8px;
          color: $primary;
          width: 100%;
        }

        input {
          width: 100%;
          padding: 1em;
          background-color: transparent;
          border-radius: 5px;
          outline: none;
          box-shadow: 1px 1px 5px #60606030;
          border: 1px solid rgba($color: $dark, $alpha: 0.5);
          margin: 1em 0 0 0;
        }
      }

      .exp {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        min-width: 150px;
        width: fit-content;
        cursor: pointer;
        padding: 5px 10px 5px 1em;

        img {
          height: 24px;
          width: 24px;
          object-fit: contain;
        }

        p {
          line-height: 24px;
          text-align: left !important;
        }
      }
      .exp:hover {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
      }
      h3 {
        text-align: center;
        padding: 8px;
        color: $primary;
        width: 100%;
      }

      h4 {
        font-size: 10pt;
        text-align: center;
        padding: 10px 0 10px 0;
      }

      img {
        height: 44px;
        width: 44px;
        object-fit: contain;
      }

      .imgSelected {
        border: 2px solid $primary;
      }

      .list {
        padding: 1em;
      }

      .item {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        color: $dark;
        margin: 16px 0 16px 0;
      }
    }

    .right_panel {
      position: absolute;
      right: 10px;
      left: auto;
      top: 10px;
      bottom: 68px;
      height: auto;
      border-radius: 0 5px 5px 5px;
      background-color: white;
      z-index: 999;

      .fa-angle-left,
      .fa-angle-right {
        padding: 8px 10px 8px 10px;
        background-color: $accent;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: -32px;
        width: 32px;
        color: white;
        border-radius: 5px 0 0 5px;
      }
      .fa-angle-up:hover,
      .fa-angle-down:hover {
        background-color: $accent;
      }
      .outer {
        overflow: hidden;
        height: 100%;
        border-radius: 5px;
      }
      .collapsible {
        height: 100%;
        position: relative;
        max-width: 400px;
        width: 100%;
        overflow: hidden !important;
        display: grid;
        grid-template-rows: auto 1fr;

        .bar4 {
          background-color: $accent;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          border-radius: 0 5px 0 0;

          p {
            font-size: small;
            padding: 8px 10px 8px 10px;
            color: white;
            text-align: center;
            cursor: pointer;
          }

          p:hover {
            color: $accent;
            background-color: $bg2;
          }

          .active {
            font-size: small;
            padding: 8px 10px 8px 10px;
            color: $accent;
            background-color: $bg2;
            text-align: center;
            cursor: pointer;
          }
        }

        .bar3 {
          background-color: $accent;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          border-radius: 0 5px 0 0;

          p {
            font-size: small;
            padding: 8px 10px 8px 10px;
            color: white;
            text-align: center;
            cursor: pointer;
          }

          p:hover {
            color: $accent;
            background-color: $bg2;
          }

          .active {
            font-size: small;
            padding: 8px 10px 8px 10px;
            color: $accent;
            background-color: $bg2;
            text-align: center;
            cursor: pointer;
          }
        }

        .r_layers {
          display: grid;
          grid-template-rows: auto 1fr;
          height: 100%;
          overflow: hidden !important;
          padding: 10px;

          hr {
            color: #74465c;
            margin: 10px 0 5px 0;
          }

          h4 {
            font-size: medium;
            color: $accent;
            font-weight: 600;
          }

          .scr {
            height: 100%;
            overflow-y: auto;

            h5 {
              margin: 5px 10px 5px 3em;
              font-size: x-small;
            }

            .theme {
              display: grid;
              grid-template-columns: auto 1fr;
              gap: 5px;
              margin: 5px 10px 5px 4em;
              font-size: x-small;
              font-style: italic;
              color: $accent;

              input {
                height: 12px;
                width: 12px;
                border: none;
                outline: none;
              }
            }

            .item {
              display: grid;
              grid-template-columns: auto auto 1fr auto auto;
              gap: 5px;
              margin: 10px 0 10px 0;
              font-size: small;

              .fa {
                padding: 2px;
                color: $accent;
                cursor: pointer;
              }
              .fa:hover {
                color: #74465c;
              }
            }
          }
        }

        .charts {
          max-width: 400px;
          width: 100%;
          padding: 10px;
          overflow: hidden;

          .list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;

            button {
              border: none;
              padding: 5px 10px 5px 10px;
              cursor: pointer;
              color: white;
            }
            button:hover {
              background-color: #74465c;
            }
          }
          hr {
            color: #74465c;
            margin: 10px 0 5px 0;
          }

          h4 {
            font-size: medium;
            // margin-bottom: 1em;
            color: $accent;
            font-weight: 600;
            text-align: left;
          }

          .area {
            height: 100%;
            overflow-y: auto;
          }
        }

        .s_panel {
          padding: 1em;
          overflow-y: auto;
          height: 100%;

          .maptest {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 9999999;
            background-color: white;
            border: 1px solid red;
          }

          label {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }

          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }

          hr {
            color: #74465c;
            margin: 10px 0 5px 0;
          }

          h4 {
            font-size: medium;
            font-weight: 600;
            text-align: left;
          }

          h5 {
            color: $dark;
            padding: 10px 0 5px 0;
          }

          .exp {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;
            font-size: small;
            cursor: pointer;
            margin: 1px 0 1px 0;
            padding: 5px;

            p {
              display: block;
              margin: auto;
              width: 100%;
            }
          }

          .exp:hover {
            box-shadow: 1px 1px 5px #60606030;
          }
        }
      }

      @include maxwidth(mobile) {
        .collapsible {
          width: 0;
        }
      }
      @include maxwidth(tablet) {
        .collapsible {
          width: 0;
        }
      }
    }

    .data_popup {
      position: absolute;
      left: 10px;
      top: 50px;
      bottom: 50px;
      overflow: hidden;
      z-index: 999;
      background-color: $bg2;
      border-radius: 5px;
      max-width: 250px;
      width: 100%;
      height: auto;

      .dcont {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        padding: 10px;
        position: relative;

        .fa-close {
          position: absolute;
          right: 10px;
          bottom: 10px;
          display: block;
          margin: 0 0 0 auto;
          color: $accent;
          cursor: pointer;
          width: fit-content;
        }
        .fa-close:hover {
          color: #74465c;
        }
      }

      .workspace_item {
        margin-bottom: 5px;
        .top {
          display: grid;
          grid-template-columns: 1fr auto;
          padding: 5px;
          background-color: wheat;
          border-radius: 2px;
          cursor: pointer;

          p {
            font-size: small;
            display: block;
            margin: auto;
            text-align: left;
            width: 100%;
          }

          i {
            font-size: medium;
            display: block;
            margin: auto;
          }
        }
        .top:hover {
          background-color: whitesmoke;
        }

        .item {
          cursor: pointer;
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          padding: 5px;
          border-radius: 2px;
          margin: 3px;
          width: 90%;
          p {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-size: small;
            margin: auto;
            text-align: left;
            width: 100%;
            clear: both;
            display: inline-block;
            white-space: wrap;
          }

          i {
            font-size: small;
            display: block;
            margin: auto;
            color: $accent;
          }
        }
        .item:hover {
          background-color: wheat;
        }
      }

      .bar {
        background-color: $accent;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        border-radius: 5px 5px 0 0;

        p {
          font-size: small;
          padding: 4px 6px 4px 6px !important;
          color: white;
          text-align: center;
          cursor: pointer;
        }

        p:hover {
          color: $accent;
          background-color: $bg2;
          border-radius: 5px 5px 0 0;
        }

        .active {
          color: $accent;
          background-color: $bg2;
          cursor: pointer;
          border-radius: 5px 5px 0 0;
        }
      }

      .dat_cont {
        padding: 1em;
      }

      .basic_styler {
        padding: 10px;
        position: relative;

        .query {
          width: 100% !important;
          height: fit-content !important;
          padding: 4px !important;
          font-size: small;
          margin: auto;
          display: block;
          border: 1px solid $accent !important;
          border-radius: 5px;
          outline: none;
        }
        button {
          padding: 6px 1em 6px 1em;
          background-color: $accent;
          color: white;
          margin: 12px auto 0 auto;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          display: block;
          min-width: 100px;

          .fa-check {
            color: white;
          }
        }

        .range_popup {
          position: absolute;
          top: -24px;
          bottom: auto;
          left: 240px;
          right: 0;
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 250px;

          .container {
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            width: 100%;
            margin: auto 1em auto 1em;
            box-shadow: 3px 3px 5px #60606040;

            h3 {
              font-weight: 600 !important;
            }

            button {
              padding: 6px 1em 6px 1em;
              background-color: $accent;
              color: white;
              margin: 12px auto 0 auto;
              border: none;
              border-radius: 5px;
              cursor: pointer;
              display: block;
              min-width: 100px;

              .fa-check {
                color: white;
              }
            }

            .columns {
              display: grid;
              grid-template-columns: 1fr 0.5fr 0.5fr;
              gap: 5px;
              margin-bottom: 8px;

              input {
                width: 100%;
                padding: 2px !important;
                font-size: small;
              }
            }
          }
        }

        .buttons {
          width: fit-content;
          margin: 5px 0 5px auto;
          display: grid;
          grid-template-columns: repeat(2, auto);
          gap: 10px;

          .fa {
            cursor: pointer;
            color: $accent;
            font-size: medium;
            padding: 3px;
          }
          .fa:hover {
            color: #74465c;
          }
        }

        input[type="number"] {
          padding: 8px 10px 8px 10px !important;
          border: 1px solid $accent !important;
          border-radius: 5px;
          color: $accent !important;
          min-width: 44px;
          text-align: center;
        }

        p {
          color: $accent;
          font-size: small;
        }

        .classes {
          max-height: 28vh;
          height: 100%;
          overflow-y: auto;
        }

        .fa {
          color: $accent;
          padding: 3px;
        }

        .row {
          display: grid;
          grid-template-columns: auto 1fr auto auto;
          gap: 10px;
          margin: 5px 0 5px 0;

          input {
            width: 16px;
            height: 16px;
            border: none;
            cursor: pointer;
            display: block;
            margin: auto;
          }

          p {
            font-size: small;
            display: block;
            width: 100%;
            margin: auto;
          }

          select {
            padding: 3px;
            background-color: $bg2;
            border: 1px solid $accent;
            border-radius: 3px;
            cursor: pointer;
            font-size: smaller;
            color: $accent;
          }
        }
      }

      .split {
        display: grid;
        grid-template-columns: auto auto !important;

        input {
          width: 24px;
        }
      }

      h3 {
        font-size: small;
        font-weight: 500;
        color: black;
        margin: 7px 0 5px 0;
      }

      br {
        height: 1px !important;
        padding: 0;
        margin: 0;
      }

      hr {
        color: #74465c;
        margin: 5px 0 10px 0;
      }
    }

    .popup {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0.5);
      display: flex;
      align-items: center;
      z-index: 999999;
      animation: zoom 1s ease;

      .popupcontainer {
        background-color: white;
        border-radius: 8px;
        padding: 1em 2em 1em 2em;
        margin: 0 auto 0 auto;
        color: #000000;
        max-width: 70vw;
        max-height: 70vh;
        height: fit-content;
        overflow-y: auto;
        width: fit-content;
        position: relative;

        h3 {
          text-align: center;
          font-size: x-large !important;
        }

        .fa-times {
          position: absolute;
          right: 1em;
          top: 10px;
          padding: 10px;
          cursor: pointer;
          color: $primary;
        }
        .fa-times:hover {
          color: $accent;
        }

        hr {
          margin-bottom: 10px;
        }

        i {
          display: block;
          width: fit-content;
          padding: 5px;
          margin: 0 0 0 auto;
          color: $accent;
        }
        i:hover {
          color: $primary;
        }

        h3 {
          padding: 0px 0 10px 0;
          font-size: medium;
        }

        form {
          textarea {
            resize: none;
            width: 100%;
            margin: auto;
            padding: 4px;
            font-size: 18px;
          }
        }

        .input-map {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }
          textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          textarea:focus {
            border-bottom: 2px solid $primary;
          }
          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
        .usrselect {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin: 10px 0 10px 0;
            font-weight: 400;
          }

          select {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        p {
          font-size: medium;
          margin: 0px 0 5px 0;

          b {
            color: #74465c;
            cursor: pointer;
          }
        }

        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 12px;
          width: 80%;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 2px solid #60606070;
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }
      }
      @include maxwidth(mobile) {
        .container {
          max-width: 85% !important;
        }
      }
      @include maxwidth(tablet) {
        .container {
          max-width: 85% !important;
        }
      }
    }

    
  }

  .cont {
    padding: 2em;
    background-color: white;
    border-radius: 5px;
    margin: 1em;

    .input-map {
      margin: 10px 0 10px 0;

      h4 {
        display: block;
        margin: 10px 0 10px 0;
        font-weight: 400;
      }
      textarea {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      textarea:focus {
        border-bottom: 2px solid $primary;
      }
      input {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      select {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }

      input[type="date"] {
        font-size: 14px;
      }

      /* Style the calendar icon in WebKit-based browsers */
      input[type="date"]::-webkit-calendar-picker-indicator {
        background-color: $accent;
        /* Change the background color */
        color: #fff;
        /* Change the text color */
        border: none;
        /* Remove the default border */
        border-radius: 4px;
        padding: 2px;
        /* Adjust the padding */
        font-size: 18px;
      }
}

    .div2equal {
      gap: 2em !important;
      margin: 2em 0 2em 0;
    }

    .img {
      position: relative;
      width: 100%;
      height: calc(25vw * 0.6);

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: center;
        border-radius: 5px;
      }

      input {
        display: none;
      }

      i {
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        left: 45%;
        position: absolute;
        bottom: -20px;
        cursor: pointer;
        background-color: $accent;
        border-radius: 5px;
      }
      i:hover {
        background-color: $dark;
        color: $accent;
      }

      @include maxwidth(tablet) {
        height: 200px !important;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 1.3;
      max-width: 80%;
      color: $primary;
      text-align: center;
      display: block;
      margin: 2em auto 10px auto;
    }

    p {
      font-size: 14px;
      line-height: 1.3;
      max-width: 60%;
      display: block;
      margin: 10px auto 10px auto;
      text-align: center;
    }
  }

  .container {
    padding: 2em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;

    .flip {
      .flip-card {
        perspective: 1000px;
        position: relative;
        height: 200px;
        cursor: pointer;

        div {
          margin: auto;
          color: white;
        }

        img {
          max-height: 100px;
          height: 100%;
          margin: auto !important;
          display: block;
          margin-bottom: 10px;
          object-fit: contain;
        }
      }

      .flip-card-inner {
        width: 100%;
        height: 100%;
        transition: transform 0.6s;
        transform-style: preserve-3d;
      }

      .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
      }

      .flip-card-front,
      .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden !important;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 1px 1px 5px #60606030;
        color: white;
        display: flex;
        align-items: center;

        h3 {
          font-size: x-large;
          font-weight: 500;
          color: #74465c;
        }

        p {
          padding: 10px;
          font-weight: 400;
          font-size: small !important;
          line-height: 2;
          color: white;
          text-align: center;
        }
      }

      .flip-card-front {
        background: #f5f4f0 !important;
        color: #917681;
      }

      .flip-card-back {
        background: white !important;
        transform: rotateY(180deg);
        color: white;

        h3 {
          text-align: center;
          margin-bottom: 10px;
          color: #74465c;
        }

        p {
          color: gray;
        }
      }
    }
    @include maxwidth(mobile) {
      grid-template-columns: 1fr !important;
    }
    @include maxwidth(tablet) {
      grid-template-columns: 1fr 1fr !important;
    }
  }

  @include maxwidth(mobile) {
    margin-top: 1px !important;
    padding-bottom: 18% !important;
    .model {
      .container {
        max-width: 80vw !important;
      }
    }
    .map {
      .leaflet-container {
        height: 60vh !important;
      }
      .sidepanel {
        .bar {
          // height: 44vh !important;
        }
        .Popup {
          height: 44vh !important;
          width: 90vw !important;
        }
      }
    }
  }

  @include maxwidth(tablet) {
    margin-top: 1px !important;
    padding-bottom: 18% !important;
    .model {
      .container {
        max-width: 80vw !important;
      }
    }
    .map {
      .leaflet-container {
        height: 60vh !important;
      }
      .sidepanel {
        .bar {
        }
        .Popup {
          height: 44vh !important;
          width: 50% !important;
        }
      }
    }
  }
}
