@import "./0_mediaquery.scss";
@import "./1_variables.scss";

.storymaps {
    height: 100%;
    width: 100%;
    padding: 1em;
    overflow-y: auto !important;

    .innercontent {
        background-color: white;
        padding: 1em;
        border-radius: 5px;
        position: relative;
        // max-width: 100%;
        // width: 100%;
        // margin: 1em auto 1em auto;
        // background-color: white;
        // border-radius: 5px;

        h1 {
            text-align: center;
            padding: 10px;
            border-bottom: 1px solid #60606020;
        }

        h4 {
            font-size: 22px;
            padding: 10px;
            margin-left: 20px;
        }

        h5 {
            font-size: 18px;
            margin: 30px 0 0 10px;
        }

        .theme {
            font-size: medium;
            font-weight: 600;
            color: #2254aa;
            padding: 10px;
            text-align: center;
        }

        p {
            padding: 10px;
            border-left: 2px solid #2254aa;
            line-height: 35px;
            text-align: justify;
            text-justify: inter-word;
        }

        img {
            width: 100%;
        }
    }

    .list {
        background-color: white;
        padding: 1em;
        border-radius: 5px;
        position: relative;

        h3 {
            padding: 10px 0 10px 0;
            font-size: medium;
        }

        button {
            display: block;
            margin: 1em auto 1em auto;
            padding: 12px;
            width: 80%;
            background-color: $primary;
            color: white;
            border-top: none;
            border-right: none;
            border-left: none;
            border-bottom: 2px solid #60606070;
            border-top: 2px solid #60606070;
            cursor: pointer;
        }

        button:hover {
            background-color: rgba($color: $primary, $alpha: 0.8);
        }

        .usrinput {
            margin: 10px 0 10px 0;

            h4 {
                display: block;
                margin: 0px 0 10px 0;
                font-weight: 400;
            }

            textarea {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
            }

            textarea:focus {
                border-bottom: 2px solid $primary;
            }

            input {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
            }

            input:focus {
                border-bottom: 2px solid $primary;
            }
        }

        .usrselect {
            margin: 10px 0 10px 0;

            h4 {
                display: block;
                margin: 0px 0 10px 0;
                font-weight: 400;
            }

            select {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
            }

            input:focus {
                border-bottom: 2px solid $primary;
            }
        }

        .step2 {
            padding: 10px;

            a,
            p,
            h4,
            h5,
            h1,
            h2 {
                padding: 8px;
            }

            h1 {
                font-size: larger;
                color: $primary;
                text-align: center;
                border-bottom: 1px solid #60606020;
            }

            h2 {
                font-size: medium;
                color: $primary;
            }

            h4 {
                color: blue;
            }

            img{
                max-width: 100%;
                width: 100%;
                min-height: 400px;
                object-fit: contain;
                margin: auto;
                object-position: center;
            }

            .myinput {
                border: 1px solid #60606020;
                box-shadow: 1px 1px 5px #60606020;
                border-radius: 5px;
                padding: 1em;
                position: relative;
                margin-top: 2em;

                h6 {
                    padding: 8px;
                    font-weight: 500;
                    font-size: medium;
                }

                .split {
                    display: grid;
                    grid-template-columns: 1fr auto;
                }

                .minput {
                   // height: 32px;
                  //  line-height: 32px;
                    border-radius: 5px;
                    width: 100%;
                    outline: none;
                    border: 1px solid orange;
                    padding: 8px;
                }

                .mtext {
                    border-radius: 5px;
                    width: 100%;
                    outline: none;
                    border: 1px solid orange;
                    padding: 8px;
                }

                .fa-times {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    padding: 10px;
                    cursor: pointer;
                    color: $accent;
                }

                .save {
                    border: none;
                    background-color: orange;
                    cursor: pointer;
                    line-height: 32px;
                    width: 32px;
                    border-radius: 5px;
                    text-align: center;
                    color: white;
                    margin: auto;
                }
            }

            .addtitle {
                margin-top: 2em;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 10px;

                h5 {
                    padding: 10px;
                    font-size: small;
                    box-shadow: 1px 1px 5px #60606020;
                    border-radius: 5px;
                    text-align: center;
                    font-weight: 500;
                    cursor: pointer;
                }

                h5:hover {
                    box-shadow: 1px 1px 5px #60606050;
                }
            }
        }
    }

    .button-toggle {
        background: white;
        border-radius: 50%;
        padding: 7px;
        border: 1px solid;
    }

}

.add-section {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
    width: 150px;
    text-align: center;
    cursor: pointer;
}

.remove-section {
    border-radius: 10px;
    padding: 10px;
    // width: 150px;
    text-align: center;
    cursor: pointer;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    // .save {
    //     border: 1px solid black;
    //     padding: 10px;
    //     border-radius: 10px;
    //     cursor: pointer;
    // }
}