@import "./0_mediaquery";
@import "./1_variables";

.DownloadsPage{
    padding: 1em;
    overflow-y: auto;
    .top{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        width: 100%;
        .tp_item {
            padding: 1em;
            background-color: white;
            border-radius: 8px;
      
            p {
              color: black;
              font-size: small;
              font-weight: 500;
              text-wrap: nowrap;
              text-align: center;
            }
      
            h6 {
              color: gray;
              font-size: small;
              font-weight: 400;
              text-align: center;
            }
      
            h1 {
              color: $primary;
              font-weight: bolder;
              margin: 5px 0 5px 0;
              font-size: large;
              text-align: center;
            }
          }
    }
    .middle{
        height: fit-content !important;
        margin: 1em 0 1em 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        gap: 1em;
        .chart{
            background-color: white;
            height: 100%;
            width: 100%;
            border-radius: 8px;
            padding: 1em;
            h3{
                color: #2254aa;
                font-size: medium;
                margin-bottom: 1em;
            }
        }
    }
    .chart{
        background-color: white;
        height: 100%;
        width: 100%;
        border-radius: 8px;
        padding: 1em;
        .two{
            margin: 1em 0 1em 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1em;
            gap: 1em;
            select{
                width: 100px;
                text-align: center;
                border: none;
            }
        }
        h3{
            color: #2254aa;
            font-size: medium;
            margin-bottom: 1em;
        }
    }
    .list{
        background-color: white;
        padding: 1em;
        border-radius: 5px;
        position: relative;
        margin-top: 1em;

        h3 {
        padding: 10px 0 10px 0;
        font-size: medium;
        }
        .row{
            .col{
                form{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 10px;
                    align-items: center;
                    .date{
                        display: flex;
                        flex-direction: column;                        
                        margin-top: -29px;
                    }
                    button{
                        margin-top: -21px;
                        padding: 10px;
                        width: 140px;
                        border: none;
                        cursor: pointer;
                    }               
                }
                .head{
                    display: grid;
                    grid-template-columns: 24px 0.3fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
                    grid-gap: 10px;
                    gap: 10px;
                    padding: 10px;
                    background-color: #f6fafd;
                }
                .wrap{
                    display: flex;
                    gap: 10px;
                    .searched{
                        background: white;
                        padding: 10px;
                        border-radius: 8px;
                        width: auto;
                        text-align: center;
                        margin-bottom: 12px;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                    }
                    select{
                        padding: 10px;
                        width: 140px;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        background: $bg;
                        margin: 0 0 2em 2em;
                        border-bottom: 2px solid bisque;
                    }
                }
                .content{
                    .row{
                        display: grid;
                        grid-template-columns: 24px 0.3fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
                        grid-gap: 10px;
                        gap: 10px;
                        padding: 10px;
                        margin: 2px 0 2px 0;
                        p{
                            font-size: small;
                        }
                    }
                    .row:hover{
                        background-color: $bg;
                        box-shadow: 1px 1px 5px #60606030;
                    }
                }           
            }
        }
    }    
}